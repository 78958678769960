.react-flow__controls-button {
  /* background-color: #525252 !important; */
  color: white !important;
  border-bottom: none !important;
}

.edgebutton-foreignobject body {
  align-items: center;
  background: transparent;
  display: flex;
  height: 30px;
  justify-content: center;
  min-height: 30px;
  width: 30px;
}

.disabled {
  background: white !important;
  opacity: 0.3 !important;
  cursor: not-allowed !important;
}

.tracing-drawer {
  width: 0% !important;
}

.react-datepicker {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", sans-serif !important;
  overflow: hidden !important;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 90px !important;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  padding-top: 8px !important;
}

.react-datepicker__navigation--previous {
  border-right-color: #cbd5e0 !important;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #a0aec0 !important;
}

.react-datepicker__navigation--next {
  border-left-color: #cbd5e0 !important;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #a0aec0 !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
}

.react-datepicker__header {
  border-radius: 0 !important;
  background: #f7fafc !important;
}

.react-datepicker,
.react-datepicker__header,
.react-datepicker__time-container {
  border-color: #e2e8f0 !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit !important;
  font-weight: 600 !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  margin: 0 1px 0 0 !important;
  height: auto !important;
  padding: 7px 10px !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: #edf2f7 !important;
}

.react-datepicker__day:hover {
  background: #edf2f7 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: #3182ce !important;
  font-weight: normal !important;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background: #2a69ac !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6.5px;
}

/* Track */
::-webkit-scrollbar-track:hover {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6f809a;
  border-radius: 10px;
}
